import React from 'react'
import PropTypes from 'prop-types'

const ProductStickyNavSection = ({
    children,
    id
}) => {
    return <React.Fragment key={`stickyNavSection-${id}`}>{children}</React.Fragment>
}

ProductStickyNavSection.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string
}

export default ProductStickyNavSection
