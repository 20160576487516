import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { config } from './MattressLayerConfig'

import useDeviceType from '@/hooks/useDeviceType'

import MattressLayerDesktop from './partials/MattressLayerDesktop'
import MattressLayerMobile from './partials/MattressLayerMobile'

import styles from './MattressLayer.module.scss'

/**
 * @param {{productCode: keyof (typeof config) }} props
*/
const MattressLayer = ({ productCode, className }) => {
    const { isDesktop } = useDeviceType()
    const mattressData = config[productCode]

    if (!mattressData) {
        return null
    }

    const sectionClassName = classNames('section', className)
    const containerClassname = classNames('container', styles.container)
    const headerClassName = classNames(styles.header, { 't-heading2-refined': isDesktop })

    const MattressLayerComponent = isDesktop ? MattressLayerDesktop : MattressLayerMobile

    return (
        <section className={sectionClassName}>
            <div className={containerClassname}>
                <h3 className={headerClassName}>{mattressData.title}</h3>
                <MattressLayerComponent mattressData={mattressData} />
            </div>
        </section>
    )
}

MattressLayer.propTypes = {
    productCode: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default React.memo(MattressLayer)
