import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './SupportValueProps.module.scss'

const SupportValueProp = ({ text, component, action }) => {
    const supportValuePropContainer = classNames(`col col--xs-12 col--md-4`, styles.col)
    const anchorClasses = classNames({
        [styles.supportValueProp]: action
    })
    const imageClasses = classNames(styles.image)
    const textClasses = classNames('t-heading4', styles.text)
    return (
        <div className={supportValuePropContainer}>
            <a className={anchorClasses} {...action}>
                <div className={imageClasses}>{component}</div>
                <h3 className={textClasses}>{text}</h3>
            </a>
        </div>
    )
}

SupportValueProp.propTypes = {
    text: PropTypes.string.isRequired,
    component: PropTypes.element.isRequired,
    action: PropTypes.object
}

export default SupportValueProp
