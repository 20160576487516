import React from 'react'
import { get } from 'lodash'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'

import { phoneNumbers, urls } from '@/constants/contact-info'

const SupportValuePropsConfig = (configKey = 'standard') => {
    /* 
    TODO: Modify icon-chat-bubble and icon-quiz svg files in the Assets repo to match other sprite svg formats, such viewbox, paths, stroke-widths, etc.
        - Both were migrated directly from Coresite-Node's SvgChat and SvgQuiz, respectively, without modification.
        - Update scss styles to align with updated sprite formats.
        - icon-phone was already transferred to the sprite format and does not need any modification in the assets repo.
    */
    const standardProps = {
        title: 'Need help deciding?',
        items: [
            {
                text: 'Chat with us',
                component: <SvgSprite spriteID='icon-chat-bubble' />,
                action: { onClick: OpenChat }
            },
            {
                text: 'Give us a ring',
                component: <SvgSprite spriteID='icon-phone' />,
                action: { href: `tel:+${phoneNumbers.standard}` }
            },
            {
                text: 'Take our mattress quiz',
                component: <SvgSprite spriteID='icon-quiz' />,
                action: { href: urls.quiz }
            }
        ]
    }

    /*
        - If different configuration are needed in the future they can be added below with the configKey set to the productCode.
        - Standard props will display by default in the absence of a configKey being passed in.
        - Currently only mattress pdps EXCEPT for the dog-bed contain support value props. 
    */
    const config = {
        'standard': standardProps,
    }

    return get(config, configKey, ['standard'])
}

export default SupportValuePropsConfig
