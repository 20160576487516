import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import ProductStickyNav from '@/components/ProductStickyNav'
import { linkConfig } from './NavLinkConfig'

const ProductStickyNavWrapper = ({
    productCode,
    children
}) => {
    const [currentId, setCurrentId] = useState('overview')
    const links = linkConfig[productCode]
    
    return (
        <div className='js-contentNav-anchor'>
            <ProductStickyNav links={links} currentId={currentId} productCode={productCode} />
            <div className='contentNav__linkedContent'>
                {React.Children.map(children, (child, index) => {
                    const sectionId = child.props.id
                    // These are the StickyNavSection elements. They have an ID that corresponds to an ID in the nav. 
                    return (
                        <Waypoint
                            key={`contentSection${index}`}
                            topOffset={'40%'}
                            bottomOffset={'50%'}
                            onPositionChange={(position) => {
                                if (position.currentPosition === 'inside') {
                                    setCurrentId(sectionId)
                                }
                            }}>
                            <div>
                                {/* These are the children of the StickyNavSection elements, aka the actual content */}
                                {React.Children.map(child.props.children, (grandChild, grandChildIndex) => {
                                    if (grandChildIndex === 0) {
                                        // The first content component gets the ID used to scroll to the section
                                        return (
                                            <div id={sectionId} key={`${sectionId}${grandChildIndex}`}>
                                                {grandChild}
                                            </div>
                                        )
                                    }
                                    return grandChild
                                })}
                            </div>
                        </Waypoint>
                    )
                })}
            </div>
        </div>
    )
}

ProductStickyNavWrapper.propTypes = {
    productCode: PropTypes.string.isRequired,
    children: PropTypes.node
}

export default ProductStickyNavWrapper
