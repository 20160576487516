import PropTypes from 'prop-types'
import useScrollToFaqsAndSpecs from '@/hooks/useScrollToFaqsAndSpecs'

const TextList = ({ data }) => {
    const { ScrollToFaqsAndSpecsButton } = useScrollToFaqsAndSpecs()
    const textList = data.hasScrollLink ? data.dynamicTexts : data.texts

    return (
        <div className='t-bodyMd'>
            {textList ? (
                <ul>
                    {textList.map((item, i) => (
                        item.receiveParams
                            ? <li key={`dynamix-text-${i}`}>{item.receiveParams && item.text(ScrollToFaqsAndSpecsButton)}</li>
                            : <li key={`dynamix-text-${i}`} dangerouslySetInnerHTML={{ __html: item.text ? item.text : item }} />
                    ))}
                </ul>
            ) : data.text}
        </div>
    )
}

TextList.propTypes = {
    data: PropTypes.object
}

export default TextList
