import { useState, createRef, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { BidirectionalTab, BidirectionalTabs } from '@/components/BidirectionalTabs'
import LegacyPicture from '@/components/LegacyPicture'
import TextList from './TextList'

import styles from '../MattressLayer.module.scss'

const MattressLayerDesktop = ({ mattressData }) => {
    const [selectedAria, setSelectedAria] = useState('panel-1')
    const constructionContainerRef = useRef()
    const layers = mattressData.layers.filter(item => item.visibleText !== false)
    const contentRefArray = layers.map(() => createRef())
    const contentRefs = useRef(contentRefArray)

    useEffect(() => {
        contentRefs.current.forEach((contentRef) => {
            if (contentRef.current.hidden === true) {
                contentRef.current.style.height = '0px'
            } else {
                contentRef.current.style.height = `${contentRef.current.firstElementChild.offsetHeight}px`
            }
        })
    }, [selectedAria])

    return (
        <div
            className={styles.constructionContainer}
            ref={constructionContainerRef}
            hidden={false}
        >
            <BidirectionalTabs
                onChange={setSelectedAria}
                selectedAria={selectedAria}
                className={styles.constructionContent}>
                {layers.map((ele, index) => (
                    <BidirectionalTab
                        key={index}
                        ariaControls={`panel-${index + 1}`}
                        id={`tab-${index}`}
                        className={classNames(styles.constructionTab, styles[`u-bgColor--${mattressData.selectedIconColor}`])}
                    >
                        <div className={styles.constructionStep}>
                            <span className={styles.constructionStepIcon}>{index + 1}</span>
                            <span className={styles.constructionStepText} dangerouslySetInnerHTML={{ __html: ele.title }} />
                        </div>
                        <div
                            role="tabpanel"
                            id={`panel-${index + 1}`}
                            aria-labelledby={`tab-${index}`}
                            className={styles.constructionDescription}
                            hidden={selectedAria !== `panel-${index + 1}`}
                            key={index}
                            ref={contentRefs.current[index]}
                            tabIndex={0}
                        >
                            <TextList data={ele} />
                        </div>
                    </BidirectionalTab>
                ))}
            </BidirectionalTabs>
            <div className={styles.constructionImageContainer}>
                {
                    mattressData.layers.map((ele, index) => {
                        const btnText = ele.btnTitle ? ele.btnTitle : index + 1
                        return (
                            <button
                                role="tab"
                                aria-selected={selectedAria === `panel-${btnText}`}
                                style={{ position: 'absolute', zIndex: 1, ...ele.imageIconPositionDesktop }}
                                onClick={() => setSelectedAria(`panel-${btnText}`)}
                                aria-hidden="true"
                                className={classNames(
                                    styles.constructionButton,
                                    { [styles[`u-bgColor--${mattressData.selectedIconColor}`]]: selectedAria === `panel-${btnText}` }
                                )}
                                tabIndex={-1}
                                key={index}
                            >
                                <span className={styles.constructionStepIcon}>{btnText}</span>
                            </button>
                        )
                    })
                }
                <LegacyPicture
                    className={styles.image}
                    folder={mattressData.pictureFolder}
                    name={mattressData.pictureName}
                    alt={mattressData.imgAlt}
                    hasWebp
                />
                {mattressData.footerDescription && (
                    <div className={styles.imageFooter}>
                        <p>{mattressData.footerDescription}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

MattressLayerDesktop.propTypes = {
    mattressData: PropTypes.object.isRequired
}

export default MattressLayerDesktop
