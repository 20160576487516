import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'

import LegacyPicture from '@/components/LegacyPicture'
import TextList from './TextList'

import styles from '../MattressLayer.module.scss'

const MattressLayerMobile = ({ mattressData }) => {
    const [mattressLayerIndex, setMattressLayerIndex] = useState(0)
    const layers = mattressData.layers.filter(item => item.visibleText !== false)

    return (
        <>
            <div className={styles.pictureWrapper}>
                {
                    mattressData.layers.map((ele, index) => {
                        const btnText = ele.btnTitle ? ele.btnTitle : index + 1
                        return (
                            <button
                                className={classNames(
                                    styles.constructionButton,
                                    { [styles[`u-bgColor--${mattressData.selectedIconColor}`]]: mattressLayerIndex === btnText - 1 }
                                )}
                                role="tab"
                                aria-selected={mattressLayerIndex === btnText - 1}
                                style={{ position: 'absolute', zIndex: 1, ...ele.imageIconPositionMobile }}
                                onClick={() => setMattressLayerIndex(btnText - 1)}
                                tabIndex={-1}
                                key={btnText - 1}
                            >
                                <span className={styles.constructionStepIcon}>{btnText}</span>
                            </button>
                        )
                    })
                }
                <LegacyPicture
                    className={styles.image}
                    folder={mattressData.pictureFolder}
                    name={mattressData.pictureName}
                    alt={mattressData.imgAlt}
                    hasWebp
                />
                {mattressData.footerDescription && (
                    <div className={styles.imageFooter}>
                        <p>{mattressData.footerDescription}</p>
                    </div>
                )}
            </div>
            <div className="row row--center-xs">
                <div className="col col--xs-10 col--sm-11">
                    <SimpleSlider
                        pageDots
                        dotLines
                        infinite={false}
                        leftIconClassName={styles.sliderChevron}
                        rightIconClassName={styles.sliderChevron}
                        sliderLabel="Mattress Layer"
                        shownSize={[1, 1, 3]}
                        className={styles.slider}
                        setIndex={setMattressLayerIndex}
                        index={mattressLayerIndex}
                    >
                        {layers.map((ele, index) => (
                            <div key={index} className={styles.sliderItem}>
                                <h6 className={styles.sliderTitle} dangerouslySetInnerHTML={{ __html: ele.title }} />
                                <div className={styles.sliderContent}>
                                    <TextList data={ele} />
                                </div>
                            </div>
                        ))}
                    </SimpleSlider>
                </div>
            </div>
        </>
    )
}

MattressLayerMobile.propTypes = {
    mattressData: PropTypes.object.isRequired
}

export default MattressLayerMobile
