import React from 'react'
import PropTypes from 'prop-types'
import SupportValueProp from './SupportValueProp'
import SupportValuePropsConfig from './SupportValuePropsConfig'
import styles from './SupportValueProps.module.scss'
import classNames from 'classnames'

// This component must be added to each pdp contentConfig file to render.
const SupportValueProps = ({ configKey, backgroundColorClass = 'u-bgColor--contrast1' }) => {
    const { title, items } = SupportValuePropsConfig(configKey)

    const sectionClasses = classNames('section', backgroundColorClass)
    const containerClasses = classNames('container--md', styles.container)
    const headingClasses = classNames('t-heading2-refined u-marginBottom--2xl')
    const rowClasses = classNames('row')

    return (
        <section className={sectionClasses}>
            <div className={containerClasses}>
                <h2 className={headingClasses}>{title}</h2>
                <div className={rowClasses}>
                    {items.map(({ text, component, action }, i) =>
                        <SupportValueProp
                            key={`supportValueProp-${i}`}
                            text={text}
                            component={component}
                            action={action}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

SupportValueProps.propTypes = {
    configKey: PropTypes.string,
    backgroundColorClass: PropTypes.string
}

export default React.memo(SupportValueProps)
